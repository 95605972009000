import { createApp,provide,h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from "apollo-link-context";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// /* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

require("@/assets/vendor/jquery/jquery.min.js");
require("@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
require("@/assets/vendor/jquery-easing/jquery.easing.min.js");
require("@/assets/css/fonts.min.css");

const httpLink = createHttpLink({
  uri: "https://violations.innovativetoll.com/api",
});

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === "Unauthenticated") {
        localStorage.clear();
        router.push("/login");
      }
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : null,
    },
  };
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
})
  .use(router)
  .use(store)
  .use(apolloProvider)
  .use(VueSweetalert2)
  .mount("#app");

